<template>
<!-- <div class="upload"> -->
  <input type="file" ref="uploadBtn" v-on:change="doParse" />
<!-- </div> -->
</template>

<script>
export default {
  name: "Upload",
  data: ()=>({
    
  }),
  methods: {
    doParse() {
      var file=this.$refs.uploadBtn.files[0];
      var reader = new FileReader();
      reader.onload = async event=>{
        var obj = JSON.parse(event.target.result);
        await appDb.clearInvoices();
        await appDb.addInvoices(obj);
        this.$emit("imported");
      };
      reader.readAsText(file);
    },
  },
}
</script>

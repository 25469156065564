import * as Vue from 'vue';
import App from './App.vue';
import db from './db';
window.appDb=db;

import dayjs from 'dayjs';
window.dayjs=dayjs;

// console.log(App);

Vue.createApp({
  components: {App},
  mounted() {
    window.appRoot=this;
    appDb.open();
  },
}).mount('body');



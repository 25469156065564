<template>
<div class="report">
  <ul class="tins" v-if="tin==null">
    <li><span>PIB</span><em>Kasa ID</em></li>
    <li v-for="xtin in tins">
      <a href="" v-on:click.prevent="loadInvoices(xtin)"><span>{{ xtin.split("/")[0] }}</span><em>{{ xtin.split("/")[1] }}</em></a>
    </li>
  </ul>
  <ul class="report" v-else>
    <li><span>PIB:</span> {{ tin.split("/")[0] }}</li>
    <li><span>Kasa:</span> {{ tin.split("/")[1] }}</li>
    <li><span>Datum početka:</span> {{ minDate }}</li>
    <li><span>Datum kraja:</span> {{ maxDate }}</li>
    <li><span>Ukupno:</span> {{ total.toFixed(2) }}</li>
    <li v-for="tax in perTax"><span>Porez {{ tax.id }} {{ tax.rate.toFixed(2) }}%:</span> {{ tax.amount.toFixed(2) }}</li>
    <li><span>Porez ukupno:</span> {{ totalTax.toFixed(2) }}</li>
  </ul>
  
</div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
export default {
  name: "Report",
  data: ()=>({
    tins: [],
    invoices: [],
    tin: null,
    device: null,
  }),
  computed: {
    minDate() {
      if (!this.invoices.length)
        return null;
      return this.formatDate(this.invoices[0], "YYYY-MM-DD HH:mm:ss");
    },
    maxDate() {
      if (!this.invoices.length)
        return null;
      return this.formatDate(this.invoices[this.invoices.length-1], "YYYY-MM-DD HH:mm:ss");
    },
    total() {
      var total=0;
      for(var invoice of this.invoices)
        total+=invoice.TotalAmount*1;
      return total;
    },
    totalTax() {
      var total=0;
      for(var invoice of this.invoices)
        for(var taxItem of invoice.TaxItems)
          total+=taxItem.Amount*1;
      return total;
    },
    perTax() {
      var result=[];
      for(var invoice of this.invoices) {
        for(var taxItem of invoice.TaxItems) {
          var index=result.findIndex(a=>a.id==taxItem.Label);
          if (index==-1) {
            result.push({id: taxItem.Label, rate: taxItem.Rate, amount: 0});
            index=result.length-1;
          }
          result[index].amount+=taxItem.Amount*1;
        }
      }
      return result;
    },
  },
  methods: {
    async loadTins() {
      this.tins=[];
      var results=await appDb.listAll();
      var tins={};
      for(var result of results)
        tins[result.TIN+"/"+result.RequestedBy]=true;
      for(var tin in tins)
        this.tins.push(tin);
    },
    async loadInvoices(tin) {
      this.tin=tin;
      this.invoices=[];
      var filter=this.tin.split("/");
      this.invoices=(await appDb.listAll()).filter(item=>
        item.TIN==filter[0] && item.RequestedBy==filter[1] && item.InvoiceType=='Normal' && item.TransactionType=='Sale'
      ).sort((a,b)=>{
        var aDate=dayjs(a.SDCTime_ServerTimeZone, 'DD.MM.YYYY. H:mm:ss').unix();
        var bDate=dayjs(b.SDCTime_ServerTimeZone, 'DD.MM.YYYY. H:mm:ss').unix();
        if (aDate<bDate) return -1;
        if (aDate>bDate) return 1;
        return 0;
      });
    },
    formatDate(item, format) {
      return dayjs(item.SDCTime_ServerTimeZone, 'DD.MM.YYYY. H:mm:ss').format(format);
    },
  },
  mounted() {
    this.loadTins();
  },
}
</script>

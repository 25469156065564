if (!window.indexedDB) {
    console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
}

var dbConfig={
  name: 'efiskalizvestaj',
}

var db={
  db: null,
  open() {
    return new Promise((resolve)=>{
      var request = window.indexedDB.open(dbConfig.name, 1);
      request.onerror = event => resolve(null);
      request.onsuccess = event => resolve(this.db=request.result);
      request.onupgradeneeded = event => {
        console.log("update needed");
        var db = event.target.result;
        var objectStore = db.createObjectStore("invoices", { keyPath: "InvoiceNumber" });
        objectStore.createIndex("TIN", "TIN", { unique: false });
      }
    });
  },
  close() {
    if (this.db)
      this.db.close();
  },
  addInvoiceTr() {
    var transaction = this.db.transaction(["invoices"], "readwrite");
    
    var completePromise = new Promise((resolve)=>{
      transaction.oncomplete = event => {resolve(true)}
      transaction.onerror = event => {resolve(false)}
    });
    return {
      transaction,
      add(invoiceData) {
        return new Promise((resolve)=>{
          var store=transaction.objectStore("invoices");
          var request=store.put(invoiceData);
          request.onerror = event => {resolve(false)}
          request.onsuccess = event => {resolve(true)}
        });
      },
      clear() {
        return new Promise((resolve)=>{
          var store=transaction.objectStore("invoices");
          var request=store.clear();
          request.onerror = event => {resolve(false)}
          request.onsuccess = event => {resolve(true)}
        });
      },
      listAll() {
        return new Promise((resolve)=>{
          var store=transaction.objectStore("invoices");
          
          var request=store.getAll();
          request.onerror = event => {resolve(false)}
          request.onsuccess = event => {resolve(request.result)}
        });
      },
      complete: completePromise
    };
  },
  async addInvoices(invoiceData) {
    var tr=this.addInvoiceTr();
    if (!Array.isArray(invoiceData))
      invoiceData=[invoiceData];
    for(var invoice of invoiceData)
      await tr.add(invoice);
    await tr.complete;
  },
  async clearInvoices() {
    var tr=this.addInvoiceTr();
    await tr.clear();
    await tr.complete;
  },
  async listAll() {
    var tr=this.addInvoiceTr();
    var response=await tr.listAll();
    await tr.complete;
    return response;
  },
};

export default db;

<template>
<div class="x">

  <div v-if="mode=='upload'" class="mode-select">
    <div class=""><div class="upload"><upload @imported="mode='report'" /></div></div>
    <div>ili</div>
    <div><button @click="mode='report'">Otvorite izveštaj za prethodno prosleđeni json</button></div>
  </div>
  
  <report v-if="mode=='report'" />

  
</div>
</template>

<script>
import Upload from './Upload.vue';
import Report from './Report.vue';
export default {
  data: ()=>({
    mode: "upload"
  }),
  components: {Upload, Report},
}
</script>
